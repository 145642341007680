import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'react-emotion';

import { Card, Header, Layout } from 'components';
import config from '../../config/site';

const Feature = styled('div')`
  margin: 25px auto 25px auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;

  .gatsby-image-outer-wrapper,
  .gatsby-image-wrapper {
    position: static !important;
  }
`;

const Grid = styled('div')`
  margin: 25px auto 25px auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${props => props.theme.gridWidth}, 1fr));
  grid-gap: 50px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  .gatsby-image-outer-wrapper,
  .gatsby-image-wrapper {
    position: static !important;
  }
`;

const Content = styled('div')`
  margin: -6rem auto 6rem auto;
  max-width: ${props => props.theme.maxWidths.general};
  padding: 0 ${props => props.theme.contentPadding} 1.45rem;
  position: relative;
`;

const Index = ({
  data: {
    headShot: { childImageSharp },
    infos: { infoEdges },
    projects: { edges },
  },
}) => {
  var feature = edges[0];

  return (
  <Layout>
    <Header
      avatar={childImageSharp.fluid}
      name={config.name}
      infos={infoEdges}
      socialMedia={config.socialMedia} />
    <Content>
      <Feature>
        <Card
          date={feature.node.frontmatter.date}
          title={feature.node.frontmatter.title}
          cover={feature.node.frontmatter.cover.childImageSharp.fluid}
          path={feature.node.fields.slug}
          areas={feature.node.frontmatter.areas}
          slug={feature.node.fields.slug}
        />
      </Feature>
      <Grid>
        {edges.filter((project, i) => {
          return i != 0;
        }).map(project => (
          <Card
            date={project.node.frontmatter.date}
            title={project.node.frontmatter.title}
            cover={project.node.frontmatter.cover.childImageSharp.fluid}
            path={project.node.fields.slug}
            areas={project.node.frontmatter.areas}
            slug={project.node.fields.slug}
            key={project.node.fields.slug}
          />
        ))}
      </Grid>
    </Content>
  </Layout>
  );
};

export default Index;

Index.propTypes = {
  data: PropTypes.shape({
    headShot: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }),
    }),
    infos: PropTypes.shape({
      infoEdges: PropTypes.array.isRequired,
    }),
    projects: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query HomeQuery {
    headShot: file(relativePath: { eq: "headShot.png" }) {
      childImageSharp {
        fluid(maxWidth: 250, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    infos: allMarkdownRemark(
      filter: { fields: { pageType: { eq: "info" } } },
      sort: { fields: [frontmatter___date] }
    ) {
      infoEdges: edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD.MM.YYYY")
            title
            link
          }
        }
      }
    }
    projects: allMarkdownRemark(
      filter: { fields: { pageType: { eq: "projects" } } },
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            cover {
              childImageSharp {
                fluid(maxWidth: 850, quality: 90, traceSVG: { color: "#328bff" }) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            date(formatString: "DD.MM.YYYY")
            title
            areas
          }
        }
      }
    }
  }
`;
